html, body, #root
    margin: 0
    padding: 0
    font-family: 'Inter', sans-serif
    user-select: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    background: #000

.bgs
    max-width: 1920px
    position: absolute
    top: 0
    left: 50%
    width: 100%
    height: 100%
    transform: translateX(-50%)
    img
        width: 100%
        display: block
        background-size: contain

.global-label
    color: var(--Lime-green, var(--primary-chartreuse-hightlight, #E1E724))
    text-align: center
    font-family: Inter
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: normal
    letter-spacing: -0.016px
    text-transform: uppercase

.global-title
    color: var(--White, #FFF)
    text-align: center
    font-family: Rubik
    font-size: 44px
    font-style: normal
    font-weight: 900
    line-height: normal
    letter-spacing: -0.52px
    text-transform: uppercase

.global-subtitle
    color: #FFF
    text-align: center
    font-family: Inter
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    letter-spacing: -0.018px
    margin-top: 24px

@media (min-width: 1000px)
    .global-title
        font-size: 35px
@media (min-width: 1200px)
    .global-title
        font-size: 44px

@media (min-width: 1400px)
    .global-label
        font-size: 16px
    .global-title
        font-size: 52px
    .global-subtitle
        font-size: 18px
