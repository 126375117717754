.Homepage
    max-width: 1920px
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center
.loading
    width: 100vw
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    color: white
    flex-direction: column
