.Section-two-Mob .banner {
  position: relative;
}

.Section-two-Mob .banner .item {
  position: relative;
  padding: 20px;
}

.Section-two-Mob .banner .item1::before {
  content: "";
  position: absolute;
  top: -7vw;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/border1.png") no-repeat;
  background-size: contain;
  pointer-events: none;
}
.Section-two-Mob .banner .item2::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/border2.png") no-repeat;
  background-size: contain;
  pointer-events: none;
}
.Section-two-Mob .banner .item3::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/border3.png") no-repeat;
  background-size: contain;
  pointer-events: none;
}

.Section-two-Mob .banner .item .subcontainer {
  position: relative; /* Para asegurar que el contenido esté por encima del pseudo-elemento */
  z-index: 1;
}
