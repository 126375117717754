.LoadingPage
    height: 100vh
    width: 100vw
    display: flex
    align-items: flex-end
    position: absolute
    z-index: 1000
    .container
        width: 100%
        display: flex
        justify-content: flex-end
        align-items: center
        color: white
        padding: 0 10vw
        color: #FFF
        font-family: "Arial Black"
        font-size: 25.5px
        font-style: normal
        font-weight: 900
        line-height: normal
        text-shadow: #FC0 1px 1px 18px
