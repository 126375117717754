.SectionTwo
    position: relative
    z-index: 1
    height: 30vw
    display: flex
    align-items: center
    justify-content: center
    max-height: 576px
    .banner
        display: flex
        justify-content: center
        gap: 90px

        .item
            .title
                text-align: center
                text-shadow: 0px 0px 24px rgba(225, 231, 36, 0.30)
                font-family: "Arial Black"
                font-size: 28px
                font-style: normal
                font-weight: 900
                line-height: 119.048%
            .description
                text-align: center
                font-family: Inter
                font-size: 14px
                font-style: normal
                font-weight: 600
                line-height: 127%
                letter-spacing: -0.018px
                max-width: 226px
                margin: 0 auto
                margin-top: 25px

            .subcontainer
                max-width: 380px
        .item1
            color: var(--Lime-green, var(--primary-chartreuse-hightlight, #E1E724))
            .description
                color: var(--White, #FFF)
        .item2
            color: #062F24
            .description
                color: var(--Black, #000)
        .item3
            color: var(--Primary-Green-Accent, #006348)
            text-shadow: 0px 4px 34px rgba(181, 181, 181, 0.50)
            .description
                color: var(--Black, #000)

@media (min-width: 1400px)

    .SectionTwo
        .banner
            gap: 130px
            .item
                .title
                    font-size: 42px
                .description
                    font-size: 18px
                    // margin-top: 25px

                .subcontainer
                    // max-width: 380px
@media (max-width: 1170px)
    .SectionTwo
        .banner
            .item1
                width: 310px!important
            .item2
                width: 255px!important
            .item3
                width: 270px!important
