.UnlockPower::before {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/border7.png") no-repeat;
  background-size: contain;
  pointer-events: none;
}

@media (max-width: 650px) {
  .UnlockPower::before {
    top: -30px;
  }
}
