.discover_more::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/border6.png") no-repeat;
  background-size: contain;
  pointer-events: none;
}
