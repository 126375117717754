.Button
    display: flex
    padding: 18px 36px
    justify-content: center
    align-items: center
    gap: 13.145px
    border-radius: 8px
    background: var(--primary-chartreuse-hightlight, #E1E724)
    color: var(--Black, #000)
    text-align: center
    border: 2px solid #E1E724
    font-family: Rubik
    font-size: 20px
    font-style: normal
    font-weight: 800
    line-height: normal
    cursor: pointer

    &.secondary
        background: transparent
        color: white
        border: 2px solid white


@media (max-width: 500px)
    .Button
        padding: 12px 24px
        font-size: 16px
