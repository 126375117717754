.SectionFour
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    height: 120vw
    max-height: 2285px
    padding-top: 6.6vw
    .body
        flex: 1
        display: flex
        flex-direction: column
        .subbody
        .subbody2
        .subbody3
            .global-title
                color: #006348!important
        .subbody5
            .global-subtitle
                margin: 0 auto
                width: 50.21vw
                max-width: 954px
            .btns
                display: flex
                justify-content: center
                gap: 16px
                margin-top: 24px

        .margin-subbody2
            height: 42vw
            max-height: 867px
        .margin-subbody3
            height: 64vw
            max-height: 1267px
        .margin-subbody4
            height: 24vw
            max-height: 660px
        .subbody5
            .line-height-subtitle
                padding-top: 18px
                padding-bottom: 40px
                line-height: 28px
    .items-more
        display: flex
        justify-content: center
        margin-top: 2vw
        gap: 30px
        .more
            text-align: center
            img
                width: 17.5vw
                max-width: 336px
            &-title
                color: var(--Primary-Gray-85, #1D2024)
                text-align: center
                font-family: Inter
                font-size: 28px
                font-style: normal
                font-weight: 700
                line-height: 110.034%
                letter-spacing: -0.032px
            &-description
                color: var(--Primary-Gray-85, #1D2024)
                text-align: center
                font-family: Inter
                font-size: 16px
                font-style: normal
                font-weight: 400
                line-height: normal
                letter-spacing: -0.024px
                &.des0
                    margin: 0 auto
                    max-width: 276px
                    text-align: center
                &.des1
                    margin: 0 auto
                    max-width: 254px
                    text-align: center
                &.des2
                    margin: 0 auto
                    max-width: 306px
                    text-align: center
                &.des3
                    margin: 0 auto
                    max-width: 276px
                    text-align: center

@media (min-width: 1400px)
    .more-title
        font-size: 32px!important
    .more-description
        font-size: 24px!important
    .SectionFour
        position: relative
        z-index: 1
        display: flex
        flex-direction: column
        height: 120vw
        max-height: 2285px
        .body
            flex: 1
            display: flex
            flex-direction: column
