.discover_more
    background: #DFE700
    padding: 64px 16px
    position: relative
    .title
        color: var(--primary-green-accent-dark, #004330)
        text-align: center
        font-size: 52px
        font-style: normal
        font-family: "Arial Black"
        font-weight: 400
        line-height: normal
    .items-more
        max-width: 650px
        margin: auto
        padding-top: 32px
        display: grid
        grid-template-columns: 1fr 1fr
        justify-items: center
        text-align: center
        gap: 32px
        img
            width: 100%
            max-width: 220px
        .more-title
            color: var(--Primary-Gray-85, #1D2024)
            text-align: center
            font-family: Rubik
            font-size: 28px
            font-style: normal
            font-weight: 700
            line-height: 110.034%
            letter-spacing: -0.032px
        .more-description
            color: var(--Primary-Gray-85, #1D2024)
            text-align: center
            font-family: Rubik
            font-size: 16px
            font-style: normal
            font-weight: 400
            line-height: normal
            letter-spacing: -0.024px

// media query

@media (max-width: 1024px)
    .discover_more
        padding-top: 78px!important
