.Section-two-Mob
    color: white
    .item
        padding: 0 18px
        min-height: 250px
        display: flex
        justify-content: center
        align-items: center
        text-align: center
        padding-top: 90px!important

        .title
            text-align: center
            text-shadow: 0px 0px 24px rgba(225, 231, 36, 0.30)
            font-family: Rubik
            font-size: 38px
            font-style: normal
            font-weight: 800
            line-height: normal
            margin: auto
            max-width: 300px
        .description
            margin-top: 20px
    .item1
        background: #05614a
        color: #E1E724
        .description
            color: white
    .item2
        background: #E1E724
        color: #002118
        .description
            color: #002118
    .item3
        background: white
        color: #004330
        .description
            color: #004330

@media (max-width: 768px)
    .Section-two-Mob
        .item
            .title
                margin: auto
                max-width: 300px
            .description
                margin: auto
                margin-top: 20px
                max-width: 342px
