
.home
    display: flex
    justify-content: center
    align-items: center
    height: 78vh
    position: relative
    min-height: 650px
    .title
        color: var(--White, #FFF)
        text-align: center
        font-family: Rubik
        font-size: 64px
        font-style: normal
        font-weight: 800
        line-height: normal
        letter-spacing: -1.28px
    .subtitle
        color: var(--White, #FFF)
        text-align: center
        font-family: Rubik
        font-size: 24px
        font-style: normal
        font-weight: 800
        line-height: normal
        margin-top: 12px
        margin-bottom: 8px
    .description
        color: var(--White, #FFF)
        text-align: center
        font-family: Rubik
        font-size: 22px
        font-style: normal
        font-weight: 400
        line-height: normal
        max-width: 480px
        margin: auto
    .btns_group
        display: flex
        justify-content: center
        gap: 20px
        margin-top: 40px
    .cards
        position: absolute
        width: 100%
        left: 0
        bottom: -80px

@media (max-width: 600px)
    .home
        display: flex
        justify-content: center
        align-items: center
        padding-left: 16px
        padding-right: 16px
        .title
            font-size: 42px
        .subtitle
            font-size: 20px
        .description
            font-size: 18px
        .btns_group
            gap: 10px

.home
    .cards-img
        position: absolute
        width: 100%
        left: 0
        bottom: 8vw
        object-fit: contain

    .home-box
        position: relative
        margin-top: -24vw

@media (min-width: 550px)
    .home
        .cards-img
            bottom: 6vw

        .home-box
            margin-top: -26vw

@media (min-width: 570px)
    .home
        .cards-img
            bottom: 2vw

        .home-box
            margin-top: -26vw

@media (min-width: 780px)
    .home
        .cards-img
            bottom: 2vw

        .home-box
            margin-top: -26vw
