.SectionOne
    height: 52vw
    position: relative
    z-index: 2
    max-height: 998px
    .header-margin
        height: 165px
        @media (max-width: 1460px)
            height: 100px
    .title
        color: var(--White, #FFF)
        text-align: center
        font-family: "Arial Black"
        font-size: 36px
        font-style: normal
        font-weight: 900
        line-height: normal
        letter-spacing: -0.6px
        text-transform: uppercase
    .subtitle
        color: var(--White, #FFF)
        text-align: center
        font-family: Inter
        font-size: 18px
        font-style: normal
        font-weight: 700
        line-height: 120%
        letter-spacing: -0.024px
    .description
        color: var(--White, #FFF)
        text-align: center
        font-family: Inter
        font-size: 18px
        font-style: normal
        font-weight: 300
        line-height: 120%
        letter-spacing: -0.024px
        margin-top: 9px

    .btns_group
        margin-top: 32px
        display: flex
        justify-content: center
        gap: 16px
        @media (min-width: 1000px)
            margin-top: 24px
        @media (min-width: 1500px)
            margin-top: 12px

@media (min-width: 1400px)
    .SectionOne
        .title
            font-size: 60px
        .subtitle
            font-size: 24px
        .description
            font-size: 24px
