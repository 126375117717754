.login-page
    position: fixed
    z-index: 1000
    height: calc(var(--vh, 1vh) * 100)
    width: 100%
    width: -webkit-fill-available
    overflow: auto

    .header
        height: 128px
        padding: 20px
        .logo
            max-width: 264px
    .footer-login
        width: 100%
        background: #383D40
        position: fixed
        bottom: 0
        display: flex
        align-items: center
        justify-content: space-between
        height: 68px
        padding: 0 20px
        .copy
            color: var(--White, #FFF)
            font-family: "Rubik"
            font-size: 14.549px
            font-style: normal
            font-weight: 400
            line-height: normal
    .my-own-button
        background-color: #2E3135!important
        color: #fff!important
        bottom: 20px
        font-family: Rubik
        position: absolute
        bottom: 88px
        left: 2rem
        transform: translateY(-50%)
        width: 187px!important
        &.hidden
            display: none
    [data-amplify-container]
        width: 100%
        max-width: 460px
    [data-amplify-router]
        border-radius: 12px
    [data-amplify-authenticator]
        margin-top: 28px
    [data-amplify-authenticator-signin]
        button[type="submit"]
            width: 187px
            margin-left: 207px
    .amplify-authenticator
        font-family: Rubik !important
        background: rgba(255, 255, 255, 0.9)
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3)
    .amplify-tabs__item
        border-top-width: 5px !important
        color: #D8D8D8
        text-align: center
        font-family: Rubik
        font-size: 17.898px
        font-style: normal
        font-weight: 800
        line-height: normal
    .amplify-tabs__item--active
        color: #004330 !important
        text-align: center !important
        font-family: Rubik !important
        font-size: 17.898px !important
        font-style: normal !important
        font-weight: 800 !important
        line-height: normal !important
        border-color: #E1E724 !important
        border-top-width: 5px !important
    .amplify-button
        background-color: #E1E724
        color: #004330
        font-family: Rubik
        width: fit-content
        margin: 0 auto
    .amplify-button[type="submit"]
        padding: 15px 30px
        margin-top: 40px
        font-size: 17.767px!important

    .amplify-button--link
        background: transparent
        outline: none
        border: none
    &:hover
        background-color: #333

    .amplify-form-header
        font-size: 1.5rem
        margin-bottom: 20px
        text-align: center
        color: #333
    .amplify-tabs__list
        border-color: transparent
    .amplify-tabs__item:first-child
        border-radius: 12px 0 0 0!important
    .amplify-tabs__item:last-child
        border-radius: 0 12px 0 0!important
    .amplify-label
        font-weight: normal
        font-size: 1rem
        color: #333

    .amplify-button--small
        background: none !important
        border: none
        text-decoration: underline
        font-size: 1rem
        cursor: pointer
        padding: 0
        margin-top: 15px
        color: var(--Primary-Green-Accent, #006348)
        text-align: center
        font-family: Rubik
        font-size: 17.767px
        font-style: normal
        font-weight: 400
        line-height: 140%
        text-decoration-line: underline

        &:hover
            color: #004734
            text-decoration: underline

@media (max-width: 768px)
    .login-page
        .footer-login
            padding: 0 10px
            flex-direction: column
            height: auto
            padding: 10px
    .amplify-authenticator
        width: 100%
    [data-amplify-authenticator-signin]
        button[type="submit"]
            width: fit-content!important
            margin-right: 0!important
            margin-left: auto !important
    .my-own-button[type="submit"]
        width: fit-content!important
    [data-amplify-authenticator]
        padding: 0 10px
