@mixin responsive-padding($min, $ideal, $max)
    padding-top: $min

    @media (min-width: 600px)
        padding-top: $ideal

    @media (min-width: 1920px)
        padding-top: $max

.SectionThree
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    justify-content: center
    height: 70vw
    max-height: 1397px
    .body
        display: flex
        flex-direction: column
        .subbody
        .img-legends
            margin: 0 auto
            width: 80vw
            max-width: 1128px
            @include responsive-padding(1vw, 1vw, 20px)
    .footer
        @include responsive-padding(1vw, 1vw, 19px)
        display: flex
        justify-content: center
    &-btn
        display: flex
        justify-content: center
        margin-top: 24px
        margin-bottom: 40px

@media (min-width: 1500px)
    .SectionThree
        height: 70vw
        max-height: 1110px
@media (min-width: 1670px)
    .SectionThree
        height: 68vw
        max-height: 1397px
@media (min-width: 1860px)
    .SectionThree
        height: 70.5vw
        max-height: 1450px

@media (min-width: 1000px) and (max-width: 1500px)
    .SectionThree
        .body
            .img-legends
                width: 65vw

.SectionThreeLegends
    display: flex
    gap: 20px
    margin: auto
    max-width: 1407px
    width: 100%
    justify-content: center

    img
        flex: 1 1 auto
        max-width: calc(20% - 40px)
        width: 100%
        height: auto
        object-fit: contain
        transition: all 0.6s

        &:hover
            transform: scale(1.1)
            border: 1.502px solid var(--Gradient, #FFF)
            box-shadow: 0px 4.823px 40.994px 0px #E1E724
