.footer
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 69px
    height: 118px
@media (max-width: 600px)
    .footer
        justify-content: space-around
        padding-top: 24px
        flex-direction: column
        height: 200px
        div
            padding-bottom: 24px
