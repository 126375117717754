@mixin responsive-padding($min, $ideal, $max)
    padding-top: $min

    @media (min-width: 600px)
        padding-top: $ideal

    @media (min-width: 1920px)
        padding-top: $max

.StepCenter
    position: relative
    padding-top: 32px
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    padding-bottom: 40px
    // background: #006348
    min-height: 350PX
    .body
        display: flex
        flex-direction: column
        .subbody
            padding: 0 16px
            @include responsive-padding(7.2vw, 7.2vw, 138px)
            .subtitle
                max-width: 650px!important
                margin: auto
                margin-top: 24px
    .btns_group
        display: flex
        justify-content: center
        gap: 20px
        margin-top: 40px

@media (max-width: 768px)
    .StepCenter
        .btns_group
            padding: 0 24px
